<template>
  <div>
      <el-divider></el-divider>
      <el-form :inline="true"
      v-model="info"
      label-width="80px"
      label-position="right">
          <el-row>

            <el-col :span="12">
                <el-form-item label="所属部门">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属部门</span>
                    </template>
                    <el-tree-select v-model="info.departmentId" :data="dptList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="员工姓名">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>员工姓名</span>
                    </template>
                    <el-input v-model="info.userName" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="性别">
                    <el-select v-model="info.sex" class="search-200">
                        <el-option :value="0" label="女"></el-option>
                        <el-option :value="1" label="男"></el-option>
                    </el-select>           
                 </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="手机号码">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>手机号码</span>
                    </template>
                    <el-input v-model="info.phone" class="search-200"></el-input>
                    <!-- 提示 -->
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="登录系统使用"
                        placement="top">
                        <i class="iconfont icon-wenhao"></i>
                    </el-tooltip>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="员工类型">
                    <el-select v-model="info.staffType" class="search-200">
                        <el-option label="正式员工" :value="1" />
                        <el-option label="临时员工" :value="2" />
                    </el-select>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                    <el-form-item label="在职状态">
                        <el-select v-model="info.workState" class="search-200">
                            <el-option :value="1" label="在职"></el-option>
                            <el-option :value="2" label="离职"></el-option>
                        </el-select>
                        <!-- 提示 -->
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="离职状态员工将无法登录系统"
                            placement="top"
                        >
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip> 
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="显示标识">
                        <el-select v-model="info.isShow" class="search-200">
                            <el-option :value="1" label="显示"></el-option>
                            <el-option :value="0" label="隐藏"></el-option>
                        </el-select>
                        <!-- 提示 -->
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="在业务单中员工列表是否显示该员工"
                            placement="top"
                        >
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>                        
                    </el-form-item>
                </el-col>

              <el-col :span="12">
                  <el-form-item label="登录密码">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>登录密码</span>
                    </template>
                    <el-input type="password" v-model="info.loginPwd" class="search-200" placeholder="不修改请留空"></el-input>
                    <!-- 提示 -->
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="不修改请留空"
                        placement="top">
                        <i class="iconfont icon-wenhao"></i>
                    </el-tooltip> 
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="职位">
                      <el-input v-model="info.zhiWei" class="search-200"></el-input>                  
                 </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="授权角色">
                    <el-select v-model="info.selRoleList" 
                    multiple 
                    class="search-200" 
                    placeholder="可以多选"
                    collapse-tags
                    collapse-tags-tooltip
                    :max-collapse-tags="3">
                        <el-option v-for="role in roleList" :key="role.roelId" :value="role.roleId" :label="role.roleName"></el-option>
                    </el-select>
                 </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="授权部门">
                    <el-tree-select v-model="info.selDptList" 
                    ref="treeDpt" 
                    :data="dptList" 
                    multiple 
                    :default-expand-all="true" 
                    show-checkbox 
                    placeholder="可以多选"  
                    class="search-200"
                    collapse-tags
                    collapse-tags-tooltip
                    :max-collapse-tags="3">
                    </el-tree-select>
                 </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="授权大区">
                    <el-select v-model="info.selAreaList"
                    multiple
                    class="search-200" 
                    placeholder="可以多选" 
                    collapse-tags
                    collapse-tags-tooltip
                    :max-collapse-tags="3">
                        <el-option v-for="area in areaList" :key="area.areaNumber" :value="area.areaNumber" :label="area.areaName"></el-option>
                    </el-select>

                </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="授权仓库">
                    <el-select v-model="info.selWhList" 
                    multiple 
                    class="search-200" 
                    placeholder="可以多选" 
                    collapse-tags
                    collapse-tags-tooltip
                    :max-collapse-tags="3">
                        <el-option v-for="wh in whList" :key="wh.warehouseNumber" :value="wh.warehouseNumber" :label="wh.warehouseName"></el-option>
                    </el-select>

                 </el-form-item>
              </el-col>

          </el-row>
      </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" v-if="CheckUserButtonAuth('BtnUserSave')" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" v-if="CheckUserButtonAuth('BtnUserSave')" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="this.btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    name:'EditUser',
    props:['editId','dataList'],
    data(){
        return{
            info:{
                id:0,
                systemNumber:'',
                userNumber:'',
                userName:'',
                departmentId:0,
                phone:'',
                sex:0,
                loginName:'',
                loginPwd:'',
                staffType:1,
                workState:1,
                isShow:1,
                zhiWei:'',
                selRoleList:[],  //选中角色列表
                selDptList:[],  //选中部门列表
                selDptInfoList:[],  //选中的部门实体列表
                selAreaList:[],   //选中的大区列表
                selWhList:[],   //选中仓库列表

            },
            userNumber:this.editId,  //用户编号
            roleList:[],  //角色全部列表
            dptList:[],   //部门全部列表
            areaList:[],  //大区全部列表
            whList:[],    //仓库全部列表
        }
    },
    methods:{
        //加载角色列表
        LoadRoleList(){
            this.$api.system.SelectRoleList().then(res=>{
                if(res.data.status === 200){
                    this.roleList = res.data.data
                }
            })
        },
        //加载仓库列表
        LoadWhList(){
            this.$api.wms.SelectCacheWarehouseList().then(res=>{
                if(res.data.status === 200){
                    this.whList = res.data.data;
                }
            })
        },
        //加载部门列表
        LoadDptList(){
            this.$api.system.GetTreeDepartmentList().then(res=>{
                if(res.data.status === 200){
                    this.dptList = res.data.data
                }else{
                    this.dptList = []
                }
            })
        },
        //加载大区列表
        LoadAreaList(){
            this.$api.system.SelectSystemAreaList().then(res=>{
                if(res.data.status === 200){
                    this.areaList = res.data.data
                }else{
                    this.areaList = []
                }
            })
        },

        //获取单个信息
        GetDataInfo(){
            if(this.userNumber !=0){
                let param ={
                    userNumber:this.userNumber
                }                
                this.$api.system.GetUserInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data
                    }else
                    {
                        this.btnClearData()
                    }
                })
            }else{
                this.btnClearData()
            }

            
        },

        //保存数据-type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            //#region 拼装选中部门实体
            this.info.selDptInfoList =[]; //实体列表
            const selectedKeys = this.$refs.treeDpt.getCheckedKeys();  //全选中
            const halfSelectedKeys = this.$refs.treeDpt.getHalfCheckedKeys();  //半选中
            selectedKeys.forEach(element => {
              let department = {
                id:0,
                userNumber:this.info.id,
                departmentId:element,
                state:2
              }
              this.info.selDptInfoList.push(department)
            });
            halfSelectedKeys.forEach(value=>{
              let department = {
                id:0,
                userNumber:this.info.id,
                departmentId:value,
                state:1
              }
              this.info.selDptInfoList.push(department)
            });
            //#endregion

          if(this.userNumber==0){
                //新增保存
                this.$api.system.SaveUserInfo(this.info).then(res=>{
                    if(res.data.status===200){
                        ElMessage.success(res.data.msg);
                        //
                        if(type==1){
                            this.btnClose();
                        }else{
                            this.btnClearData();
                            this.userNumber =0;
                        }
                        
                    }
                    else{
                        ElMessage.error(res.data.msg);
                    }
                }).catch(e=>{
                    console.log(e)
                })
          }
          else
          {
            //编辑保存
            this.$api.system.SaveUserInfo(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //关闭
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                        this.userNumber =0;
                    }
                }
                else{
                    ElMessage.error(res.data.msg);
                }
            })
          }
            
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                id:0,
                userNumber:'',
                userName:'',
                departmentId:this.info.departmentId,  //新增默认上次选择的部门
                phone:'',
                sex:0,
                loginName:'',
                loginPwd:'',
                staffType:1,
                workState:1,
                isShow:1,
                zhiWei:''
            }
            this.info = newInfo;
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.userNumber === this.userNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.userNumber = prevInfo.userNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.userNumber === this.userNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.userNumber = prevInfo.userNumber
                this.GetDataInfo()
            }
        },

    },
    mounted(){
        this.LoadRoleList();  //加载角色列表
        this.LoadDptList();  //加载部门列表
        this.LoadAreaList();  //加载大区列表
        this.LoadWhList();   //加载仓库列表       
        this.GetDataInfo();
    }
}
</script>

<style>

</style>