<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="盘点单" name="tabCheck">
            <TabCheck></TabCheck>

            <el-divider>盘点单明细</el-divider>
            <TabCheckDetail></TabCheckDetail>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabCheckRecord></TabCheckRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabCheckState></TabCheckState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="checkInfo.info">
        <el-button type="warning" v-if="checkInfo.info.state ==2" @click="btnUpdateCheckStockNum">刷新库存</el-button>
        <el-button type="primary" v-if="CheckUserButtonAuth('BtnCheckSave') && checkInfo.info.state <=2" @click="btnSaveData">保存</el-button>
        <el-button type="warning" v-if="checkInfo.info.checkNumber!='' && (checkInfo.info.state == 1 || checkInfo.info.state == 4)" @click="btnOpenCheck()">开始盘点</el-button>
        <el-button type="primary" v-if="CheckUserButtonAuth('BtnCheckResult') && checkInfo.info.state ==2" @click="BatchUpdateCheckDetail">提交盘点结果</el-button>
        <el-button type="success" v-if="checkInfo.info.state == 2" @click="btnUpdateCheckState(3)">盘点完成</el-button>
        <el-button type="warning" v-if="CheckUserButtonAuth('BtnCheckCheck') && checkInfo.info.state == 3" @click="BoxCheckState.isVisible=true">审核</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核状态-弹框 -->
    <el-dialog title="状态审核"
    v-model="BoxCheckState.isVisible"
    width="300px">
        <el-divider></el-divider>
        <div>盘点单审核是否通过?</div>
        <template #footer>            
            <el-button type="primary" @click="btnUpdateCheckState(5)">通过</el-button>
            <el-button type="danger" @click="btnUpdateCheckState(4)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import TabCheck from '@/views/Wms/Check/TabCheck.vue'
import TabCheckDetail from '@/views/Wms/Check/TabCheckDetail.vue'
import TabCheckRecord from '@/views/Wms/Check/TabCheckRecord.vue'
import TabCheckState from '@/views/Wms/Check/TabCheckState.vue'

import { ElLoading, ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
export default {
    props:['editId','dataList'],
    components:{
        TabCheck,
        TabCheckDetail,
        TabCheckRecord,
        TabCheckState
    },
    data(){
        return{
            info:{
                activeName:'tabCheck',
                checkNumber:this.editId,
            },
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['checkInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetCheckInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.checkNumber!=0){
                let param = {
                    checkNumber:this.info.checkNumber
                }
                this.$api.wms.SingleCheckAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetCheckInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据
        btnSaveData(){
            this.$api.wms.SaveCheckAllInfo(this.checkInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose()
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //开始盘点
        btnOpenCheck(){            
            this.btnUpdateCheckState(2)
        },

        //提交盘点结果
        BatchUpdateCheckDetail(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            let ckDetailList = [];
            this.checkInfo.detailList.forEach(item=>{
                let detailInfo = {
                    id:item.id,
                    unitId:item.unitId,
                    unitName:item.unitName,
                    costUnitPrice:item.costUnitPrice,
                    stockNum:item.stockNum,
                    checkNum:item.checkNum,
                    diffNum:item.diffNum,
                    checkState:2,  //0:未盘点 1:待复盘 2:已盘点
                }
                ckDetailList.push(detailInfo);
            })
            this.$api.wms.BatchUpdateCheckDetail(ckDetailList).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //更新盘点单状态
        btnUpdateCheckState(state){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            let param = {
                checkNumber:this.checkInfo.info.checkNumber,
                state:state
            }
            this.$api.wms.UpdateCheckState(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新
                    this.checkInfo.info.state = state;
                    this.BoxCheckState.isVisible = false;
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //清空数据
        btnClearData(){
            var newInfo = {
                info:{
                    checkNumber:'',
                    checkMode:1,
                    checkName:'',
                    departmentId:this.userDepartmentId,
                    state:1,
                    isEdit:0,
                },
                detailList:[],
                recordList:[],
                stateList:[]
            }
            this.SetCheckInfo(newInfo);
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.checkNumber === this.info.checkNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.checkNumber = prevInfo.checkNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.checkNumber === this.info.checkNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.checkNumber = prevInfo.checkNumber
                this.GetDataInfo()
            }
        },

        //刷新库存
        btnUpdateCheckStockNum(){
            if(this.checkInfo.info.checkNumber==null || this.checkInfo.info.checkNumber==''){
                ElMessage.error('请先保存盘点单');
                return false;
            }

            let param = {
                checkNumber:this.checkInfo.info.checkNumber,
                detailList:this.checkInfo.detailList
            }
            this.$api.wms.UpdateCheckStockNum(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //刷新数据
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style scoped>
.SingleInfo {min-height: 200px;}
</style>