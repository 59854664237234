<template>
  <div>
    <!-- 统计单日交易量、交易额 -->

    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>单日交易统计</h3>
        </el-col>        
    </el-row>

    <el-row style="margin-bottom:20px;" :gutter="5">
        <el-col :span="6"></el-col>
        <el-col :span="6" class="title_right">
            日期：<el-date-picker
                    v-model="volumeDay"
                    :editable="false"
                    :clearable="false"
                    type="date"
                    size="default"
                    @change="GetDataInfo"
                />
        </el-col>
        <el-col :span="6">
          部门：
          <el-tree-select v-model="departmentId" :data="departmentList" :default-expand-all="true" :clearable="true" class="search-200" @change="GetDataInfo"/>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="btnNextDate(-1)">上一天</el-button>
          <el-button type="primary" @click="btnNextDate(1)">下一天</el-button>
        </el-col>
    </el-row>


    <!-- 合计结果 -->
    <div style="display:flex;justify-content:space-around;min-width:1400px;">

      <div class="statBox">
        <div class="statDrawer">
          <div style="width:65%">
            <div>总交易额</div>
            <div class="statValue"><span class="redSize">{{info.totalPrice}}</span><span>元</span></div>
          </div>
          <div>
            <div>总交易量</div>
            <div class="statValue"><span class="blueSize">{{info.totalNum}}</span><span>单</span></div>
          </div>
        </div>
        <div class="statDrawer">
          <div style="width:65%">
            <div>总成本</div>
            <div class="statValue"><span class="redSize">{{info.costTotalPrice}}</span><span>元</span></div>
          </div>
          <div>
            <div>
              <i class="iconfont icon-zulinziyuan" style="font-size:30px;color:#ddd817;"></i>
            </div>
            <div style="font-size:20px;font-weight:600;color:#ddd817;">
              总销售
            </div>
          </div>
        </div>
      </div>

      <div class="statBox">
        <div class="statDrawer">
          <div style="width:65%">
            <div>交易额</div>
            <div class="statValue"><span class="redSize">{{info.salePrice}}</span><span>元</span></div>
          </div>
          <div>
            <div>单数</div>
            <div class="statValue"><span class="blueSize">{{info.saleNum}}</span><span>单</span></div>
          </div>
        </div>
        <div class="statDrawer">
          <div style="width:65%">
            <div>总成本</div>
            <div class="statValue"><span class="redSize">{{info.saleCostPrice}}</span><span>元</span></div>
          </div>
          <div>            
            <div>
              <i class="iconfont icon-pifashichang" style="font-size:30px;color:red;"></i>
            </div>
            <div style="font-size:20px;font-weight:600;color:red;">
              销售单
            </div>
          </div>
        </div>
      </div>


      <div class="statBox">
        <div class="statDrawer">
          <div style="width:65%">
            <div>交易额</div>
            <div class="statValue"><span class="redSize">{{info.retailPrice}}</span><span>元</span> </div>
          </div>
          <div>
            <div>单数</div>
            <div class="statValue"><span class="blueSize">{{info.retailNum}}</span><span>单</span> </div>
          </div>
        </div>
        <div class="statDrawer">
          <div style="width:65%">
            <div>总成本</div>
            <div class="statValue"><span class="redSize">{{info.retailCostPrice}}</span><span>元</span></div>
          </div>
          <div>
            <div>
              <i class="iconfont icon-dianpu" style="font-size:30px;color:#ed6940;"></i>
            </div>
            <div style="font-size:20px;font-weight:600;color:#ed6940;">
              零售单
            </div>
            
          </div>
        </div>
      </div>

      <div class="statBox">
        <div class="statDrawer">
          <div style="width:65%">
            <div>交易额</div>
            <div class="statValue"><span class="redSize">{{info.purchasePrice}}</span><span>元</span></div>
          </div>
          <div>
            <div>单数</div>
            <div class="statValue"><span class="blueSize">{{info.purchaseNum}}</span><span>单</span></div>
          </div>
        </div>
        <div class="statDrawer">
          <div style="width:65%">
            <div></div>
            <div></div>
          </div>
          <div>
            <div>
              <i class="iconfont icon-ERP_gongyingshang" style="font-size:30px;color:#9f43f1;"></i>
            </div>
            <div style="font-size:20px;font-weight:600;color:#9f43f1;">
              采购单
            </div>
          </div>
        </div>
      </div>

      <div class="statBox">
        <div class="statDrawer">
          <div style="width:65%">
            <div>毛利润</div>
            <div class="statValue"><span class="redSize">{{info.profitPrice}}</span><span>元</span></div>
          </div>
          <div>
            <div>毛利率</div>
            <div class="statValue"><span class="blueSize">{{info.profitRate}}</span><span>%</span></div>
          </div>
        </div>
        <div class="statDrawer">
          <div style="width:65%">
            <div></div>
            <div></div>
          </div>
          <div>
            
            <div>
              <i class="iconfont icon-chart-bar" style="font-size:30px;color:#30afef;"></i>
            </div>
            <div style="font-size:20px;font-weight:600;color:#30afef;">
              毛利润
            </div>

          </div>
        </div>
      </div>

    </div>

    <!-- 统计图 -->
    <div id="main" class="echarts" style="width:100%;height:500px;min-width:1400px;" ></div>
    <div class="dataSource">
        数据来源：采购单、销售单、零售单
    </div>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'  //用户关联部门
import * as echarts from 'echarts'  //Echarts 图标组件
import moment from 'moment';
export default {
    mixins:[UserDptList],
    data(){
        return{
            info:{
                retailNum:0,   //零售总交易量
                retailPrice:0,  //零售总交易额
                hourList:['0时','1时','2时','3时','4时','5时','6时','7时','8时','9时','10时','11时','12时','13时','14时','15时','16时','17时','18时','19时','20时','21时','22时','23时'],  //小时列表
                retailBillNumList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],   //零售交易量列表
                retailTotalPriceList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],  //零售交易额列表

                saleNum:0,  //销售单总交易量
                salePrice:0,
                saleBillNumList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],   //销售交易量列表
                saleTotalPriceList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],  //销售交易额列表

                purchaseBillNumList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],  //采购交易量列表
                purchaseTotalPriceList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],  //采购交易额列表
            },
            volumeDay:moment(new Date()).format("YYYY-MM-DD"),
            departmentId:0, //部门
        }
    },
    methods:{
        //示例
        myEcharts(){
            var myChart = echarts.init(document.getElementById('main'));
            
            //配置图表
            var option = {
              tooltip: {
                trigger: 'axis',   //显示标签
              },
              legend:{
                top:'bottom',
                data:['零售额','销售额','采购额','零售量','销售量','采购量'] //示例图
              },
              xAxis: {
                type: 'category',
                data: this.info.hourList,
              },
              yAxis: [
                  {
                      type: 'value',
                      name:'交易额',
                      position: 'left',
                      axisLabel: {
                          formatter: '{value} 元'
                      }
                  },
                  {
                      type:'value',
                      name:'交易量',
                      position: 'right',
                      axisLabel: {
                          formatter: '{value} 单'
                      }
                  },
              ],
              series: [
                
                {
                  name:'零售额',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.retailTotalPriceList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#33ccff'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 元'
                    }
                  },
                },
                {
                  name:'销售额',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.saleTotalPriceList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#DC143C'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 元'
                    }
                  },
                },
                {
                  name:'采购额',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.purchaseTotalPriceList,
                  type: 'bar',
                  itemStyle:{
                    color:'#9F5F9F'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 元'
                    }
                  },
                },

                {
                  name:'零售量',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.retailBillNumList,
                  type: 'line',  //线状图
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#33ccff'
                  },  //颜色
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 单'
                    }
                  },
                  connectNulls:true,  //连上断开的点
                },
                {
                  name:'销售量',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.saleBillNumList,
                  type: 'line',  //线状图
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#DC143C'
                  },  //颜色
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 单'
                    }
                  },
                  connectNulls:true,  //连上断开的点                  
                },
                {
                  name:'采购量',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.purchaseBillNumList,
                  type: 'line',  //线状图
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#9F5F9F'
                  },  //颜色
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 单'
                    }
                  },
                  connectNulls:true,  //连上断开的点
                },
              
              ]
            };
            myChart.setOption(option);
        },

        //获取数据
        async GetDataInfo(){
            //清空部门时会造成departmentId=undefined
            if(this.departmentId==undefined){
              this.departmentId=0;
            }

            let param = {
              volumeDay:moment(this.volumeDay).format("YYYY-MM-DD"),
              departmentId:this.departmentId,
            }
            await this.$api.stat.StatVolumeDayInfo(param).then(res=>{
                if(res.data.status === 200){
                    this.info = res.data.data;
                    this.myEcharts();
                }
            })
        },

        //上一天、下一天
        btnNextDate(num){
          var dateTime = new Date(this.volumeDay);
          dateTime = dateTime.setDate(dateTime.getDate()+num);
          this.volumeDay = moment(dateTime).format("YYYY-MM-DD");
          this.GetDataInfo();
        }

    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style scoped>
.title{text-align: center;}
.title_right{text-align: right;}

/** 新框样式 */
.statBox {border: 1px solid #ddddd4;width: 100%;height: 180px;background-color: #fff;margin-right:10px;border-radius:5px;box-shadow: 7px 7px 5px 0px rgb(241 238 238 / 75%);padding: 5px 0px 0px 10px;}
.statBox .statDrawer{display:flex;justify-content:flex-start;height:50%;}
.statBox .statDrawer .blueSize{color: #3673e6;font-weight:bold;}
.statBox .statDrawer .redSize{color: #e72552;font-weight:bold;}
.statBox .statDrawer .statValue{padding-top: 10px;}
.statBox .summary {font-size:20px;font-weight:bold;color:#30afef;}
/* 备注  */
.dataSource{margin-left: 120px;font-size: 14px;color: #ccc;}
</style>