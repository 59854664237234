<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
        <el-form :inline="true">

            <el-form-item label="大区名称">
                <el-input v-model="info.search.areaName" class="search-200"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

        </el-form>
        </el-col>
    </el-row>

    <div>
    <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
            <el-button class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>            
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                        
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>          
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="areaNumber" type="selection" width="50" />  
            <el-table-column prop="areaNumber" label="大区编号" />
            <el-table-column prop="areaName" label="大区名称" />
            <el-table-column prop="sort" label="排序"/>
            <el-table-column label="操作" width="130">
            <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.areaNumber)">详情</el-button>
                <el-button size="small" type="danger" @click="btnDeleteInfo(scope.row.areaNumber)">删除</el-button>
            </template>
            </el-table-column>
        </el-table>

    </div>

    <!-- 页码 -->
    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="400"
      :draggable="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditSystemArea :editId="info.id" @closeBox="btnCloseBox"></EditSystemArea>
    </el-dialog>

  </div>
</template>

<script>
import EditSystemArea from '@/views/system/Area/EditSystemArea.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    components:{
        EditSystemArea
    },
    data(){
      return{
        info:{
          dataList:[],  //Table数据
          page:1,   //页数
          size:50,  //单页记录数
          total:0,  //总记录数
          id:0,  //新增、编辑 的参数
          ids:[],   //删除 的参数
          search:{  //搜索条件
            areaName:'',  //大区名称 
          }
        },
        //编辑-弹出框
        BoxEditInfo:{
          isVisible:false,
          title:'新增大区'
        }

      }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                areaName:this.info.search.areaName,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.system.PageSystemAreaList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.areaNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        
        //新增编辑部门信息
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增大区"
            }else{
                this.BoxEditInfo.title = "编辑大区"
            }  
            this.BoxEditInfo.isVisible = true;
        },
        //删除按钮
        btnDeleteInfo(id){
            ElMessageBox.confirm('确认要删除该选中项吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                let param = [id]
                this.$api.system.DeleteSystemArea(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        this.GetDataList()
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消 
            })
            
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            ElMessageBox.confirm('确认要删除该选中项吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                this.$api.system.DeleteSystemArea(this.info.ids).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList()
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })            
        },
        
        //重置按钮
        btnResetSearch(){
            this.info.search.areaName='',
            this.GetDataList()
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },


    },
    mounted(){
        this.GetDataList()  //加载数据
    },
}
</script>

<style>

</style>