/**
 * 统计管理模块接口列表
 */


 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const stat = {

    //货品量统计
    ListStatGoodsNums(param){
        return axios.post(`${base.base}/Stat/ListStatGoodsNums`,param)
    },


    //账户统计
    StatisticsAccount(param){
        return axios.post(`${base.base}/Stat/StatisticsAccount`,param)
    },
    
    //导出账户统计
    ExportStatisticsAccount(param){
        return axios.post(`${base.base}/Stat/ExportStatisticsAccount`,param)
    },

    //营业额统计
    GetStatTurnover(param){
        return axios.post(`${base.base}/Stat/GetStatTurnover`,param)
    },

    //单品价格走势图
    GetHistoryPriceStat(param){
        return axios.post(`${base.base}/Stat/GetHistoryPriceStat`,param)
    },

    //单月数据统计图
    GetStatMonthTurnover(param){
        return axios.get(`${base.base}/Stat/GetStatMonthTurnover`,{
            params:param
        })
    },

    //全年数据统计图
    GetStatYearTurnover(param){
        return axios.get(`${base.base}/Stat/GetStatYearTurnover`,{
            params:param
        })
    },

    //全年财务统计图
    GetStatYearFinance(param){
        return axios.get(`${base.base}/Stat/GetStatYearFinance`,{
            params:param
        })
    },

    //全年财务分类统计
    GetStatYearFinanceBillType(param){
        return axios.get(`${base.base}/Stat/GetStatYearFinanceBillType`,{
            params:param
        })
    },

    //全年财务会计科目统计
    GetStatYearFinanceSub(param){
        return axios.get(`${base.base}/Stat/GetStatYearFinanceSub`,{
            params:param
        })
    },

    //#region 营业员业绩

    //营业员业绩统计
    PageSalerStatList(param){
        return axios.post(`${base.base}/Stat/PageSalerStatList`,param)
    },
    //计算营业员业绩
    BatchStatSaler(param){
        return axios.post(`${base.base}/Stat/BatchStatSaler`,param)
    },
    //删除营业员业绩
    DeleteStatSaler(param){
        return axios.post(`${base.base}/Stat/DeleteStatSaler`,param)
    },

    //#endregion


    //#region 交易量统计

    //单日-交易量、交易额统计
    StatVolumeDayInfo(param){
        return axios.post(`${base.base}/Volume/StatVolumeDayInfo`,param)
    },

    //单月-交易量、交易额统计
    StatVolumeMonthInfo(param){
        return axios.post(`${base.base}/Volume/StatVolumeMonthInfo`,param)
    },

    //单年-交易量、交易额统计
    StatVolumeYearInfo(param){
        return axios.post(`${base.base}/Volume/StatVolumeYearInfo`,param)
    },

    //#endregion

 }

 export default stat