<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">
            
            <el-form-item label="财务单号">
                <el-input v-model="info.search.billNumber" maxlength="20" class="search-150"></el-input>
            </el-form-item>
            
            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="交易账户">
                <el-select v-model="info.search.accountNumber" clearable class="search-200">
                    <el-option v-for="accountInfo in selectAccountList" :key="accountInfo.accountNumber" :label="accountInfo.accountName" :value="accountInfo.accountNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="财务类型">
                <el-select v-model="info.search.billType" class="search-150">
                    <el-option label="全部" :value="0" />
                    <el-option v-for="typeInfo in info.financeTypeList" :key="typeInfo.typeId" :label="typeInfo.typeName" :value="typeInfo.typeId"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="审核状态">
                <el-select v-model="info.search.stateList" multiple class="search-150" collapse-tags collapse-tags-tooltip>
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="新建单"></el-option>
                    <el-option :value="2" label="已驳回"></el-option>
                    <el-option :value="3" label="已审核并上报"></el-option>
                    <el-option :value="4" label="已完成"></el-option>
                    <!-- <el-option :value="5" label="已结款"></el-option>
                    <el-option :value="6" label="已结算"></el-option> 
                    <el-option :value="10" label="已关单"></el-option> -->
                </el-select>
            </el-form-item>

            <el-form-item label="支付状态">
                <el-select v-model="info.search.payState" class="search-150" >
                    <el-option :value="-1" label="全部"></el-option>
                    <el-option :value="0" label="未支付"></el-option>
                    <el-option :value="1" label="已支付"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="会计科目">
                <el-tree-select v-model="info.search.selectSubIdList" :data="selectSubjectList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200"> 
                </el-tree-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-200">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="审核人">
                <el-select v-model="info.search.receiver" filterable clearable class="search-150">
                    <el-option v-for="trade in info.checkerList" :key="trade.tradeNumber" :label="trade.tradeName" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="创建日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item label="用款日期">
                <el-date-picker
                    v-model="info.search.payDatePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnFinanceRefresh')" class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
                <el-button v-if="CheckUserButtonAuth('BtnFinanceAdd')" class="iconfont icon-add" type="primary" plain @click="BoxAddInfo.isVisible=true">新增</el-button>
                <el-button class="iconfont icon-dingdan" type="warning" plain @click="btnOpenCycle">生成对账单</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出PDF"
                    placement="top">
                    <el-button class="iconfont icon-xiazaiPDF" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnShowBoxPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                    </el-tooltip>
                </el-button-group> 
            </el-col>
        </el-row>

        <!-- 数据列表 -->
        <el-table :data="dataList" border stripe show-summary :height="GetDataHeight" style="width: 100%" @select="btnCheckBox" @select-all="btnCheckBox">
            <el-table-column prop="id" type="selection" width="55"></el-table-column>
            <el-table-column label="财务单号" width="160">
                <template v-slot="scope">
                    <span>{{scope.row.billNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="departmentName" label="部门名称" width="120">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.departmentName"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="billTypeName" label="财务类型" min-width="100">
            </el-table-column>

            <el-table-column label="交易者类型" min-width="100">
                <template #default="scope">
                    <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                    <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                    <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                    <el-tag v-if="scope.row.tradeType ==4" type="warning">散客</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="tradeName" label="交易者名称" width="150"></el-table-column>  
            <el-table-column label="会计科目" min-width="140">
                <template #default="scope">
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.subFullName"
                    placement="top"
                  >
                  <div class="cellOverFlow">{{scope.row.subName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <!-- <el-table-column label="计算方式" width="90">
                <template #default="scope">
                    <el-tag v-if="scope.row.way ==0" type="danger">减</el-tag>
                    <el-tag v-if="scope.row.way ==1">加</el-tag>
                </template>
            </el-table-column> -->
                      
            <el-table-column label="交易账户" min-width="100">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.accountName"
                        placement="top"
                    >
                        <div class="cellOverFlow">{{scope.row.accountName}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="payablePrice" label="应付金额(元)" width="110"></el-table-column>
            <el-table-column prop="totalPrice" label="实付金额(元)" width="110"></el-table-column>
            <el-table-column label="审核状态" width="100">
                <template #default="scope">
                    <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                    <el-tag v-if="scope.row.state ==2" type="danger">已驳回</el-tag>
                    <el-tag v-if="scope.row.state ==3" type="warning">已审核并上报</el-tag>
                    <el-tag v-if="scope.row.state ==4" type="info">已完成</el-tag>
                    <el-tag v-if="scope.row.state ==5" type="info">已结款</el-tag>
                    <el-tag v-if="scope.row.state ==6" type="info">已结算</el-tag>
                    <el-tag v-if="scope.row.state ==10">已关单</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="支付状态" width="100">
                <template #default="scope">
                    <el-tag v-if="scope.row.payState ==0" type="danger">未支付</el-tag>
                    <el-tag v-if="scope.row.payState ==1" type="info">已支付</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="receiverName" label="指定审核人" min-width="110"></el-table-column>
            <el-table-column label="对账标识" min-width="110">
                <template #default="scope">
                    <el-tag v-if="scope.row.cycleFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="scope.row.cycleFlag == 1" type="info">已生成</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.createTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.createTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="用款时间" width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.payTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.payTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="标识">
                <template v-slot="scope">
                    <el-button v-if="scope.row.isCheap == 1" type="danger" circle size="small" >惠</el-button>
                </template>
            </el-table-column>

            <el-table-column label="备注" width="100">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.remark"
                        placement="top">
                        <div class="cellOverFlow">{{scope.row.remark}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.billNumber)">详情</el-button>
               
              </template>
            </el-table-column>
        </el-table>
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditFinance :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditFinance>
    </el-drawer>

    <!-- 新增 财务弹出框 -->
    <el-dialog title="新增财务单"
    v-model="BoxAddInfo.isVisible"
    :draggable="true"
    width="60%"
    :close-on-click-modal="false"    
    :destroy-on-close="true"
    @closed="GetDataList">
        <AddFinance @closeBox="btnCloseAddFinance"></AddFinance>
    </el-dialog>

    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    :draggable="true"
    :close-on-click-modal="false"
    width="80%">
        <PrintView :dataList="dataList" @closeBox="btnClosePrint"></PrintView>
    </el-dialog>

    <!-- 生成对账单-弹出框 -->
    <el-dialog title="生成对账单"
    v-model="BoxCreateCycle.isVisible"
    width="70%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <AddCycle :numberList="info.ids" :newCycle="cycleInfo" @closeBox="btnCloseCycle"></AddCycle>
    </el-dialog>

  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus'
import EditFinance from '@/views/Fms/Finance/EditFinance.vue'
import UserDptList from '@/utils/mixins/UserDptList.js'   //部门-下拉列表-目录树
import accountList from '@/utils/mixins/AccountList.js'  //账户-下拉列表
import subjectList from '@/utils/mixins/SubjectList.js'   //会计科目-下拉列表-目录树
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表

import PrintView from '@/views/Fms/Finance/PrintView.vue' //打印-弹出框
import AddCycle from '@/views/Fms/Cycle/AddCycle.vue'  //生成对账单-弹出框
import AddFinance from '@/views/Fms/Finance/AddFinance.vue'  //新增财务单-弹出框
export default {
    components:{
        EditFinance,
        AddFinance,
        PrintView,
        AddCycle,
    },
    mixins:[UserDptList,accountList,subjectList,AllTradeList],
    data(){
        return{
            dataList:[],  //Table数据
            info:{                
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    billNumber:'',    //财务单号
                    accountNumber:'',  //账户编号
                    selectDepartmentList:[],   //部门组
                    billType:0,   //财务类型
                    datePoint:'',    //记账时间范围
                    payDatePoint:'',  //付款时间范围
                    stateList:[],    //状态组
                    payState:-1,   //支付状态
                    selectSubIdList:[],   //会计科目
                    tradeNumber:'',  //交易者编号
                    originType:0,  //原始单据类型
                    receiver:'',   //审核人编号
                },
                financeTypeList:[],  //财务类型-下拉列表
                checkerList:[],  //审核人-下拉列表
            },
            //对账单数据
            cycleInfo:{
                departmentId:0,  //部门编号
                tradeType:0,  //交易者类型
                tradeNumber:'',  //交易者编号
                priceList:[],  //单价数组
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'财务单详情'
            },
            //打印框
            BoxPrint:{
                isVisible:false,
            },
            //生成对账单-弹出框
            BoxCreateCycle:{
                isVisible:false,
            },
            //新增-弹出框
            BoxAddInfo:{
                isVisible:false
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-270
        }
    },
    methods:{

        //审核人列表
        SelectCheckerList(){
            let param = {
                tradeType:3
            }
            this.$api.erp.SelectTradeList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.checkerList = res.data.data;
                    return false;
                }
            })
            this.info.checkerList = [];
        },

        //财务类型
        SelectFinanceTypeList(){
            this.$api.finance.SelectFinanceTypeList().then(res=>{
                if(res.data.status === 200){
                    this.info.financeTypeList = res.data.data;
                }
            })
        },
    
        //加载数据列表
        GetDataList(){
            //记账时间范围
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            //会计科目
            let newSubList = []
            if(this.info.search.selectSubIdList !=null){
                newSubList = this.info.search.selectSubIdList.map(item=>{
                    return item
                })
            }

            //支付时间范围
            if(this.info.search.payDatePoint == null){
                this.info.search.payDatePoint = ''
            }

            let param = {
                billNumber:this.info.search.billNumber,
                accountNumber:this.info.search.accountNumber,
                departmentList:newDepartmentList,
                billType:this.info.search.billType,
                subIdList:newSubList,
                stateList:this.info.search.stateList,
                payState:this.info.search.payState,
                tradeNumber:this.info.search.tradeNumber,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                startPayDate:this.DateFormat(this.info.search.payDatePoint[0]),
                endPayDate:this.DateFormat(this.info.search.payDatePoint[1]),
                originType:this.info.search.originType,
                receiver:this.info.search.receiver,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.ListFinance(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //选中多选框
        btnCheckBox(selection){
            this.info.ids = selection.map(item=>item.billNumber);  //财务单编号数组
            this.cycleInfo.priceList = selection.map(item=> parseFloat(item.totalPrice)); //总价数组
            this.cycleInfo.departmentId = selection.find(item=>item).departmentId;  //部门编号
            this.cycleInfo.tradeNumber = selection.find(item=>item).tradeNumber;  //交易者编号
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //财务单详情
        btnEditInfo(id){
            this.info.id = id
            this.BoxEditInfo.isVisible = true;
        },

        //更新财务单状态
        UpdateFinanceState(billNumber,state){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            let param = {
                billNumber:billNumber,
                state:state
            }
            this.$api.finance.UpdateFinanceState(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.billNumber ='';  //财务单号
            this.info.search.accountNumber='';  //账户编号
            this.info.search.selectDepartmentList=[];  //所属部门
            this.info.search.billType = 0;  //财务类型
            this.info.search.stateList = [];   //状态列表
            this.info.search.payState = -1;    //支付状态
            this.info.search.datePoint = '';   //创建日期
            this.info.search.payDatePoint='';  //付款日期
            this.info.search.selectSubIdList = [];   //会计科目
            this.info.search.tradeNumber ='';   //交易者
            this.info.search.originType = 0;   //原始单据类型
            this.info.search.receiver = '';   //审核人编号
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList();
            
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //打开打印框
        btnShowBoxPrint(){
            this.BoxPrint.isVisible =true;
        },
        //关闭打印框
        btnClosePrint(){
            this.BoxPrint.isVisible =false;
        },


        //导出财务单到Excel
        btnExportInfo(){
            //时间范围
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            //会计科目
            let newSubList = []
            if(this.info.search.selectSubIdList !=null){
                newSubList = this.info.search.selectSubIdList.map(item=>{
                    return item
                })
            }

            //支付时间范围
            if(this.info.search.payDatePoint == null){
                this.info.search.payDatePoint = ''
            }

            let param = {
                billNumber:this.info.search.billNumber,
                accountNumber:this.info.search.accountNumber,
                departmentList:newDepartmentList,
                billType:this.info.search.billType,
                subIdList:newSubList,
                stateList:this.info.search.stateList,
                payState:this.info.search.payState,
                tradeNumber:this.info.search.tradeNumber,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                startPayDate:this.DateFormat(this.info.search.payDatePoint[0]),
                endPayDate:this.DateFormat(this.info.search.payDatePoint[1]),
                originType:this.info.search.originType,
                receiver:this.info.search.receiver,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.ExportFinance(param).then(res=>{
                if(res.data.status === 200){
                    const alink = document.createElement('a');
                    alink.href = res.data.data;
                    document.body.appendChild(alink);
                    alink.click();
                    document.body.removeChild(alink)
                }
                else
                {
                    ElMessage.error(res.data.msg)
                }
            }).catch(err=>{
                console.log(err)
            })
        },

        //生成对账单-打开
        btnOpenCycle(){
            this.BoxCreateCycle.isVisible = true;
        },
        //生成对账单-关闭
        btnCloseCycle(){
            this.BoxCreateCycle.isVisible=false;
        },


        //关闭弹出框
        btnCloseAddFinance(){
            this.BoxAddInfo.isVisible = false;
        },

    },
    mounted(){
        this.SelectCheckerList();  //审核人列表 
        this.SelectFinanceTypeList();  //财务类型列表
        this.GetDataList();   //加载数据
    }
}
</script>

<style>

</style>